<template>
  <div class="reserve">
    <div>
      <img src="https://thcdn.gggamedownload.com/source/assets/erweima.png">
    </div>
    <div>
      <a target="_blank" href="https://uri6.com/tkio/A7Znqia"><img src="https://thcdn.gggamedownload.com/source/assets/ios.png"></a>
    </div>
    <div>
      <img src="https://thcdn.gggamedownload.com/source/assets/android.png" @click="open">
    </div>

  </div>
</template>
<script>
import { reqGetLinks } from "@/api/news.js"
import { getLinksParams } from "@/utils/getDownloadLinks.js"
export default {
  name: 'FiexdReserve',
  props: {
    open: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      lostworld_ios: "",
      lostworld_android: "",
      lostworld_pc: ""
    };
  },
  created() {
    this.getDownloadLinks()
  },
  methods: {
    clickDownLoad(url, type) {
      const link = document.createElement('a');
      if (type === 'lostworld_android') {
        const ourkey = 'RILvS9HYMOsiFptQ75dgW38V6Bh2Jyw';
        const time = Date.parse(new Date()) / 1000;
        const uri = url.split("com")[1]; // 取com后面的所有值
        const key = this.$md5(`${uri}${ourkey}${time}`); // 转 md5 密钥计算参数

        link.href = url + `?key=${key}&time=${time}`; // 设置下载链接
      } else {
        link.href = url; // 设置下载链接
      }
      link.download = url.substring(url.lastIndexOf("/") + 1); // 设置下载文件的名称 取最后一个/后面的内容为下载名字
      // 将 <a> 标签添加到 DOM 中
      document.body.appendChild(link);
      // 触发点击事件
      link.click();
      // 删除添加的 <a> 标签
      document.body.removeChild(link);
    },
    async getDownloadLinks() {
      const res = await reqGetLinks(getLinksParams)

      res.data.forEach(item => {
        this[item.keywords] = item.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.reserve {
  cursor: pointer;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 200px;
  background: url('https://thcdn.gggamedownload.com/source/assets/neiye/sucai-11.png') no-repeat center;
  padding: 7px;
  background-size: 100%;
  width: 194px;
  min-height: 308px;
  z-index: 999;
  div {
    margin: 0 auto;
    img {
      width: 100%;
    }
    &:nth-child(1) {
      margin-top: 17px;
      width: 120px;
      // height: 96px;
      margin-bottom: 25px;
    }
    &:nth-child(2) {
      width: 150px;
      height: 45px;
    }
    &:nth-child(3) {
      margin-top: 15px;
      width: 150px;
      height: 45px;
    }
  }
}
</style>
