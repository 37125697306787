<template>
  <div v-if="showGame" class="ReserveModels">
    <div class="quan">
      <div v-if="showForm" class="box">
        <span class="close"><img src="https://thcdn.gggamedownload.com/source/assets/X.png" @click="closeModel"></span>
        <div class="anz">
          <img src="https://thcdn.gggamedownload.com/source/assets/android_y.png">
        </div>
        <div class="form">
          <div> <span v-if="phoneMsg">{{ phoneMsg }}</span> <input v-model="formData.phone" placeholder="请输入手机号" type="text"> </div>
          <div> <span v-if="codeMsg">{{ codeMsg }}</span> <input v-model="formData.code" placeholder="请输入验证码" type="text"><button :disabled="disabled" @click="handleSendCode">{{ msg }}</button> </div>
          <div class="email"> <span v-if="emailMsg">{{ emailMsg }}</span> <input v-model="formData.mail" placeholder="请输入常用邮箱" type="text"> </div>
          <div class="submit">
            <img src="https://thcdn.gggamedownload.com/source/assets/book_now2.png" @click="handleSubmit">
          </div>
        </div>
      </div>
      <div v-else class="succse">
        <img class="succ" src="https://thcdn.gggamedownload.com/source/assets/bonanza.png" alt="">
        <img class="x" src="https://thcdn.gggamedownload.com/source/assets/X.png" alt="" @click="closeModel">
      </div>
    </div>
  </div>
</template>

<script>
import { reqSendCode, reqSubmit } from '@/api/home';
export default {
  name: 'ReserveModels',

  data() {
    return {
      count: 60,
      msg: '发送验证码',
      disabled: false,
      timeID: '',
      showGame: false,
      showForm: true,
      formData: {
        project: 'lostWord',
        phone: '',
        os: 'android',
        mail: '',
        code: ''
      },
      emailMsg: '',
      phoneMsg: '',
      codeMsg: ''
    }
  },
  created() {
  },
  methods: {
    handleBlur(type) {
      if (type === 'phone') {
        const phoneReg = /^1\d{10}$/
        if (this.formData.phone === '') {
          this.phoneMsg = '手机号不能为空'
          return
        }
        if (!phoneReg.test(this.formData.phone)) {
          this.phoneMsg = '请输入正确的手机号'
          return
        } else {
          this.phoneMsg = ''
        }
      }
      if (type === 'code') {
        if (this.formData.code === '') {
          this.codeMsg = '验证码不能为空'
          return
        } else {
          this.codeMsg = ''
        }
      }
      if (type === 'mail') {
        const reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
        if (this.formData.mail === '') {
          this.emailMsg = '邮箱不能为空'
          return
        }
        if (!reg.test(this.formData.mail)) {
          this.emailMsg = '邮箱格式不正确'
          return
        } else {
          this.emailMsg = ''
        }
      }
    },
    async handleSendCode() {
      const phoneReg = /^1\d{10}$/
      if (this.formData.phone === '') {
        this.phoneMsg = '手机号不能为空'
        return
      }
      if (!phoneReg.test(this.formData.phone)) {
        this.phoneMsg = '请输入正确的手机号'
        return
      } else {
        this.phoneMsg = ''
      }
      const params = {
        phone: this.formData.phone,
        os: 'android'
      }
      try {
        const res = await reqSendCode(params)
        if (res.msg === 'repeat') {
          return alert('您已经预约过了')
        }
      } catch (error) {
        console.log(error)
      }
      this.disabled = true
      this.count = 60
      this.timeID = setInterval(() => {
        if (this.count <= 0) {
          this.disabled = false
          clearInterval(this.timeID)
          this.msg = '发送验证码'
          return
        }
        this.count--
        this.msg = `请${this.count}秒后重试`
      }, 1000)
    },
    async handleSubmit() {
      const reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
      const phoneReg = /^1\d{10}$/
      if (this.formData.phone === '') {
        this.phoneMsg = '手机号不能为空'
        return
      }
      if (!phoneReg.test(this.formData.phone)) {
        this.phoneMsg = '请输入正确的手机号'
        return
      } else {
        this.phoneMsg = ''
      }
      if (this.formData.code === '') {
        this.codeMsg = '验证码不能为空'
        return
      } else {
        this.codeMsg = ''
      }
      if (this.formData.mail === '') {
        this.emailMsg = '邮箱不能为空'
        return
      }
      if (!reg.test(this.formData.mail)) {
        this.emailMsg = '邮箱格式不正确'
        return
      } else {
        this.emailMsg = ''
      }

      try {
        const res = await reqSubmit(this.formData)
        if (res.code == 200) {
          this.showForm = false
        } else if (res.code == 500 && res.msg == "error code") {
          alert("验证码错误！");
        } else if (res.code == 500 && res.msg == "repeat") {
          alert("您已预约过啦！");
        }
      } catch (error) {
        console.log(error)
      }
    },
    closeModel() {
      clearInterval(this.timeID)
      Object.assign(this.$data, this.$options.data())
      this.showGame = false
    }

  }
}
</script>
<style lang="scss" scoped>
.ReserveModels {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.5);
  // background-color: #000;
  z-index: 999;
  .quan {
    position: absolute;
   width: 100vw;
  height: 100vh;
     top: 0;
      left: 0;
      bottom: 0;
      right: 0;
  }
  .box {
    position: absolute;
    width: 727px;
    height: 441px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: url('https://thcdn.gggamedownload.com/source/assets/game_reserve.png') no-repeat center;
    background-size: 727px 441px;
    padding-top: 63px;

     .close {
      position: absolute;
      cursor: pointer;
      right: -40px;
      width: 44px;
      height: 44px;
      img {
        width: 100%;
      }
  }
    .anz {
      text-align: center;
      padding-top: 20px;
      img {
        width: 130px;
      }
    }
    .form {
      padding: 20px 100px 0;
       div {
         position: relative;
         display: flex;
         width: 100%;
         height: 45px;
         margin-top: 25px;
         &:nth-child(2) input {
           border-right: none;
         }
         input {
           width: 100%;
           height: 100%;
           border: 1px solid #000;
           border-radius: 5px 0 0 5px;
           padding-left: 20px;
           font-size: 18px;
         }
         button {
           width: 200px;
           font-size: 18px;
           color: #fff;
           background-color: #858ba2;
           border-radius: 0  13px 13px 0;
           border: none;
           cursor: pointer;
           padding: 0 20px;
         }
         span {
           position: absolute;
           bottom: -25px;
           color: red;
         }
       }

       .submit {
         width: 100%;
         img {
           width: 165px;
           margin: 0 auto;
         }
       }
    }
  }
  .succse {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    .succ {
      width: 561px;
    }
    .x {
      position: absolute;
      width: 44px;
    }
  }
}
</style>
