<template>
  <div class="head" @mouseleave="handleCloseQQ">
    <div class="box">
      <div class="left">
        <img src="https://thcdn.gggamedownload.com/source/assets/head_logo.png">
        <!-- <img src="@/assets/head_logo.png"> -->
      </div>
      <div class="right">
        <ul class="rightUl">
          <li v-for="item in titleList" :key="item.id" :class="{activeText:item.id === activeText}" @click="handleTitleClick(item)">

            <div style="margin-bottom: 5px;">{{ item.title }}</div>
            <div>{{ item.en }}</div>
          </li>
        </ul>
        <div class="link">
          <!-- <div><a target="_blank" href="https://weibo.com/u/7490517968">  <img src="https://thcdn.gggamedownload.com/source/assets/weibo-x.png"></a></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Head',
  data() {
    return {
      titleList: [
        {
          id: 1,
          title: '首页',
          en: 'HOME',
          path: '/'
        },
        {
          id: 2,
          title: '新闻',
          en: 'NEWS',
          path: '/newsLIst'
        },
        {
          id: 3,
          title: '人物',
          en: 'CHARACTORS',
          path: '/'

        },
        // {
        //   id: 4,
        //   title: '视觉盛宴',
        //   en: 'VIDEOS',
        //   path: "/soundtrack"
        // }
        // {
        //   id: 5,
        //   title: '同人小站',
        //   en: 'FAN-FICTION'
        // }
      ],
      activeText: 1,
      active_weixinID: false,
      active_QQID: false
    }
  },
  created() {
  },
  methods: {
    handleTitleClick({ id, path, title }) {
      this.activeText = id
      if (this.$route.path === '/') {
        if (title === '新闻') {
          this.getDOM('xinwen')
          return
        }
        if (title === '人物') {
          this.getDOM('renwu')
          return
        }
        if (title === '视觉盛宴') {
          this.getDOM('shipin')
          return
        }
      }
      if (title === '人物' && this.$route.path !== '/') {
        this.$router.push({
          path,
          name: 'Index',
          params: {
            type: 'renwu'
          }
        })
        return
      }
      if (title === '视觉盛宴') {
        return this.$router.push({
          path,
          query: {
            type: 'video'
          }
        })
      }
      this.$router.push({
        path
      })
    },
    getDOM(id) {
      const DOM = document.getElementById(id)
      if (!DOM) return
      const top = DOM.offsetTop;
      document.documentElement.scrollTop = top;
      document.body.scrollTop = top;
    },
    openWeiXin() {
      this.active_weixinID = true
      this.active_QQID = null
    },
    handleQQclick(id) {
      // this.active_QQID = !id
    },
    handleCloseQQ() {
      this.active_QQID = null
      // console.log(this.)
    }
  }

}
</script>

<style lang="scss" scoped>
.head {
  position: relative;
  z-index: 99;
  height: 80px;
  border-bottom: 2px solid rgba(6, 8, 9, 0.2);
  // background-color: rgba(23, 28, 34,.7);
  .box {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      img {
        position: absolute;
        top: 0;
          left: 0;
        width: 450px;
      }
    }
    .right {
      display: flex;
      .rightUl {
        display: flex;
        li {
          margin-left: 60px;
          text-align: center;
          color: #8195a0;
          cursor: pointer;
          position: relative;

        }
        // .activeText {
        //   color: #fff;
        // }
      }
      .link {
        margin-left: 30px;
        width: 280px;
        padding-right: 100px;
        justify-content: space-around;
        display: flex;
        color: #fff;
        align-items: center;
        img {
          cursor: pointer;
        }
        div {
          position: relative;
        }
        .hover {
            position: absolute;
            width: 172px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%) translateY(115%);
            text-align: center;
            color: #fff;
            // &.QQhover {
            //   border: 1px solid #fff;
            //   padding: 10px 0;
            // }
            img {
              width: 161px;
            }
          }

          .QQ {
            position: relative;
        .hover {
            position: absolute;
            width: 200px;
            top: 50%;
            left: 50%;
            height: 108px;
            transform: translateX(-50%) translateY(25%);
            text-align: center;
            color: #fff;
            &.QQhover {
              padding: 10px 0;
              background-color: #fff;
              color: #000;
              border-radius: 30px;
            }

          }
          }
      }
    }
  }
}
</style>
